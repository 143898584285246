import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "@global/store/app";
import appConfig from "@global/store/app-config";
import verticalMenu from "@global/store/vertical-menu/";
import filter from "@global/store/global-store/filter";
import admin from "@/app/admin";
// import website from "@/app/website";

Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        filter,
        ...admin,
        // ...website
    },
    strict: process.env.DEV
});
